<template>
  <div class="admin-create">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <h4 class="create-title">{{ $lang.app.editing }}</h4>
    <div class="admin-form-wrapper">
      <div v-if="faqItem" class="admin-form">
        <Form @submit="submit">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.category }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field as="select" name="type"
                     v-model="faqItem.type">
                <option v-for="type in helper.types" :key="type.value"
                        :value="type.value">{{ type.label }}</option>
              </Field>
            </div>
            <ErrorMessage name="type" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.question }} ({{ $lang.app.ru }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleRu" type="text"
                     v-model="faqItem.titleRu"/>
            </div>
            <ErrorMessage name="titleRu" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.question }} ({{ $lang.app.kg }}) <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="titleKg" type="text"
                     v-model="faqItem.titleKg"/>
            </div>
            <ErrorMessage name="titleKg" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.answer }} ({{ $lang.app.ru }}) <i>*</i></label>
            <VueEditor v-model="faqItem.textRu" />
            <ErrorMessage name="textRu" class="recovery-label-error" />
          </div>

          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.answer }} ({{ $lang.app.kg }}) <i>*</i></label>
            <VueEditor v-model="faqItem.textKg" />
            <ErrorMessage name="textKg" class="recovery-label-error" />
          </div>

          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.update }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import { Field, Form, ErrorMessage } from 'vee-validate';
import { VueEditor } from "vue3-editor";
export default {
  components: {
    Field,
    Form,
    ErrorMessage,
    VueEditor,
  },
  data() {
    return {
      faqItem: null,
      helper: {
        types: [
          {
            value: 'SCHOOL',
            label: this.$lang.app.school,
          },
          {
            value: 'PRESCHOOL',
            label: this.$lang.app.preschool,
          }
        ]
      }
    }
  },
  methods: {
    submit(values, actions) {
      this.$axios.put(`/qna`, this.faqItem).then(() => {
        this.$snotify.success(this.$lang.app.resource_updated_successfully);
        this.$router.push('/admin/faq')
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
      })
    },
    fetchResource() {
      this.$axios.get(`/qna/${this.$route.params.id}`).then(({data}) => {
        this.faqItem = data
      })
    }
  },
  mounted() {
    this.fetchResource()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/create.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/form.css';
@import '../../../assets/styles/form.css';
</style>